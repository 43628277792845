import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { medWrapper, colors, headlineTwo, headlineThree } from "../Utilities"
import SocialFollow from "./SocialFollow"
import FlyPicker from "./FlyPicker"

const isBrowser = () => typeof window !== "undefined"

const ImageGallery = ({ postImages, feed }) => {
  const loadImageFunction = (d, s, id) => {
    var js

    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = "https://embedsocial.com/cdn/ht.js"
    d.getElementsByTagName("head")[0].appendChild(js)
  }

  useEffect(() => {
    if (isBrowser()) {
      loadImageFunction(document, "script", "EmbedSocialHashtagScript")
    }

    // <script>(
    //   function(d, s, id) {
    //     var js;

    //     if(d.getElementById(id)) {
    //       return
    //     }
    //     js = d.createElement(s); js.id = id;
    //     js.src = "https://embedsocial.com/cdn/ht.js";
    //     d.getElementsByTagName("head")[0].appendChild(js);

    //   }(document, "script", "EmbedSocialHashtagScript"))
    // </script>
  }, [])

  return (
    <ImageGallerySection>
      <div className="insta-title-wrapper">
        <div className="insta-title">
          <p>Gallery</p>
          <h2>Follow the Current</h2>
          <div className="insta-title-follow">
            <SocialFollow />
            <p>
              <a
                href={`https://www.instagram.com/driftoutwest/`}
                target="_blank"
                rel="noopener nofollow"
              >
                @driftoutwest
              </a>
            </p>
          </div>
          <FlyPicker info={`three`} />
        </div>
      </div>
      <div className="instagram-gallery">
        <div
          class="embedsocial-hashtag"
          data-ref="cc4967935788039306788807b5c8fe43e5eee7f4"
        ></div>
      </div>
      {/* <div className="insta-wrapper">
        {postImages.map((image, index) => {
          const imageFluid =
            image.localFile &&
            image.localFile.childImageSharp &&
            image.localFile.childImageSharp.fluid
              ? image.localFile.childImageSharp.fluid
              : null
          if (imageFluid) {
            return (
              <ImageWrap
                key={index}
                href={`https://www.instagram.com/driftoutwest/`}
                target="_blank"
                rel="noopener nofollow"
              >
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={image.alt_text}
                />
              </ImageWrap>
            )
          } else {
            return null
          }
        })}
      </div> */}
      {/* <div className="instagram-gallery">
        <div dangerouslySetInnerHTML={{ __html: feed }} />
      </div> */}
    </ImageGallerySection>
  )
}

const ImageGallerySection = styled.section`
  padding-top: 15rem;

  .insta-title-wrapper {
    ${medWrapper};
  }

  .insta-title {
    position: relative;
    width: 100%;
    text-align: center;

    p {
      ${headlineThree};
      margin: 0;
      color: ${colors.colorAccent};
      text-transform: uppercase;
    }

    h2 {
      ${headlineTwo};
      margin: 0;
      color: ${colors.colorPrimary};
      text-transform: uppercase;
    }

    .insta-title-follow {
      display: flex;
      justify-content: center;

      p {
        display: inline-block;
        margin-left: 2rem;
        text-transform: lowercase;
      }
    }
  }

  .insta-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 200rem;
    margin: 5rem auto;
    padding-right: 0;
    padding-left: 0;
  }

  .instagram-gallery {
    width: 100%;
    max-width: 110rem;
    margin: 2rem auto;

    ${"" /* iframe {
      position: relative !important;
      display: block !important;
      min-height: 325rem;

      @media (min-width: 768px) {
        min-height: 150rem;
      }

      @media (min-width: 1025px) {
        min-height: 150rem;
      }
    } */}
  }
`

const ImageWrap = styled.a`
  width: calc(50vw - 2rem);
  margin: 1rem;

  @media (min-width: 768px) {
    width: calc(33.33vw - 2rem);
    margin: 1rem;
  }

  @media (min-width: 1025px) {
    width: calc(25vw - 5rem);
    margin: 2.5rem;
  }

  .gatsby-image-wrapper {
    height: calc(50vw - 2rem);

    @media (min-width: 768px) {
      height: calc(33.33vw - 5rem);
    }

    @media (min-width: 1025px) {
      height: calc(25vw - 5rem);
    }
  }

  img {
    width: 100%;
  }
`

export default ImageGallery
