import React, { useState, useEffect } from "react"
import axios from "axios"
import styled from "styled-components"

import { medWrapper, colors, headlineTwo, headlineThree } from "../Utilities"
import SocialFollow from "./SocialFollow"
import FlyPicker from "./FlyPicker"

const InstagramGallerySection = styled.section`
  padding-top: 15rem;

  .insta-title-wrapper {
    ${medWrapper};
  }

  .insta-title {
    position: relative;
    width: 100%;
    text-align: center;

    p {
      ${headlineThree};
      margin: 0;
      color: ${colors.colorAccent};
      text-transform: uppercase;
    }

    h2 {
      ${headlineTwo};
      margin: 0;
      color: ${colors.colorPrimary};
      text-transform: uppercase;
    }

    .insta-title-follow {
      display: flex;
      justify-content: center;

      p {
        display: inline-block;
        margin-left: 2rem;
        text-transform: lowercase;
      }
    }
  }

  .insta-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 200rem;
    margin: 5rem auto;
    padding-right: 0;
    padding-left: 0;
  }
`

const InstaImage = styled.a`
  width: calc(50vw - 2rem);
  margin: 1rem;

  @media (min-width: 768px) {
    width: calc(33.33vw - 2rem);
    margin: 1rem;
  }

  @media (min-width: 1025px) {
    width: calc(25vw - 5rem);
    margin: 2.5rem;
  }

  .gatsby-image-wrapper {
    height: calc(50vw - 2rem);

    @media (min-width: 768px) {
      height: calc(33.33vw - 5rem);
    }

    @media (min-width: 1025px) {
      height: calc(25vw - 5rem);
    }
  }

  img {
    width: 100%;
  }
`

const InstagramGallery = () => {
  const [posts, setPosts] = useState([])

  const getInstagPosts = async () => {
    const instaposts = await axios.get("/.netlify/functions/instagram")
    setPosts(instaposts.data)
  }

  useEffect(() => {
    getInstagPosts()
  }, [])

  console.log("gramz", posts)

  return (
    <InstagramGallerySection>
      <div className="insta-title-wrapper">
        <div className="insta-title">
          <p>Gallery</p>
          <h2>Follow the Current</h2>
          <div className="insta-title-follow">
            <SocialFollow />
            <p>@driftoutwest</p>
          </div>
          <FlyPicker info={`three`} />
        </div>
      </div>
      <div className="insta-wrapper">
        {posts.map((gram) => {
          return (
            <InstaImage
              key={gram.id}
              href={gram.url}
              target="_blank"
              rel="noopener nofollow"
            >
              <img src={gram.thumbnail} alt={gram.caption} />
            </InstaImage>
          )
        })}
      </div>
    </InstagramGallerySection>
  )
}

export default InstagramGallery
