import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"
import InstagramGallery from "../components/InstagramGallery"
import ImageGallery from "../components/ImageGallery"

const Instagram = (props) => {
  const metaTitle = props.data.page.acf._dow_meta_title
  const metaDescription = props.data.page.acf._dow_meta_description
  const metaImage = props.data.page.acf._dow_meta_image.localFile.publicURL

  const heroFluid =
    props.data.page.acf._dow_hero_image.localFile.childImageSharp.fluid
  const displayLogo = props.data.page.acf._dow_hero_main_logo

  const postImages = props.data.page.acf._dow_instagram_gallery
  const instagramFeed = props.data.page.acf._dow_instagram_feed

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage imgFluid={heroFluid} displayLogo={displayLogo} />
      {/* <InstagramGallery  /> */}
      <ImageGallery postImages={postImages} feed={instagramFeed} />
    </Layout>
  )
}

export const query = graphql`
  query instagramPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _dow_meta_title
        _dow_meta_description
        _dow_meta_image {
          localFile {
            publicURL
          }
        }

        _dow_hero_main_logo
        _dow_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        _dow_instagram_feed

        _dow_instagram_gallery {
          alt_text
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
              original {
                height
                width
              }
            }
          }
        }
      }
    }
  }
`

export default Instagram
